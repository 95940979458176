import React, { useState, useContext } from "react";
import Link from "next/link";
import "react-tabs/style/react-tabs.css";
import Meta from "../../components/Meta";
import GoogleIcon from "@mui/icons-material/Google";
import { Trans, useTranslation } from "react-i18next";
import { getAuth, signInWithPopup, GoogleAuthProvider, signInWithRedirect, signInAnonymously } from "firebase/auth";
import { useRouter } from "next/router";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import FacebookIcon from "@mui/icons-material/Facebook";
import { AuthContext } from "./../../context/AuthContext";
import { doc, setDoc, getDoc } from "firebase/firestore";
import { db } from "../../utils/fire";
import { ref, get, getDatabase, set, child } from "firebase/database";
import firebase from 'firebase/app';
import { Metamask_comp_login } from "../../components/metamask/Metamask";
import { useEffect } from "react";
const Login = () => {
  const router = useRouter();
  const contextObj = useContext(AuthContext);

  React.useEffect(() => {
    // contextObj.currentUser && Object.keys(contextObj.currentUser )?.length>0 && router.push('/')
  }, [contextObj]);

  React.useEffect(() => {
     
  
    const handleResults = async () => {
           // This gives you a Google Access Token. You can use it to access the Google API.
       
         const user = contextObj?.currentUser
     
         const database = getDatabase();
         const dbRef = ref(database, `users/${user.uid}`);

         const chatRooms = await getDoc(
           doc(db, "userChats", user.uid)
         );

         if (!chatRooms.exists()) {
           setDoc(doc(db, "userChats", user.uid), {});
         }

         get(child(ref(getDatabase()), `users/${user.uid}`)).then(
           (snapshot) => {
             if (snapshot.exists()) {
               const user = snapshot.val()
              if( user.hasOwnProperty('onboarded')){
               if(user.onboarded)
               router.push("/");
               else{
                 router.push("/login/intro");
               }
              }
              else{
                router.push("/login/intro");
              }
            
             } else {
               set(ref(database, `users/${user.uid}`), {
                 email: user.email,
                 displayName: user.displayName,
                 phoneNumber: user.phoneNumber,
                 verified: false,
                 brand: false,
                 influencer: false,
                 purchasesMade: 0,
                 onboarded: false,
                 rating: null,
                 moneySpent: 0,
                 photoURL: user.photoURL,
                 uid: user.uid,
               });
               router.push("/login/intro");
             }
           }
         );
         // ...
    }



   if(contextObj?.currentUser && Object.keys(contextObj?.currentUser)?.length>0 && !contextObj?.currentUser?.isAnonymous){
 try{
 
  handleResults()
 }
 catch(e) {

 }
   }
  }, [contextObj?.currentUser]);

  const { t } = useTranslation();

  const [itemActive, setItemActive] = useState(1);
  const [acc, setAcc] = useState(null);
  const [data, setData] = useState(null);
  const tabItem = [
    {
      id: 1,
      text: "Ethereum",
      icon: "ETH",
    },
    {
      id: 2,
      text: "Torus",
      icon: "torus",
    },
    {
      id: 4,
      text: "Mobile Wallet",
      icon: "mbl-wallet",
    },
  ];

  const handleUser = async ({user, metawalletid, data}) => {
    const database = getDatabase();
    const dbRef = ref(database, `users/${metawalletid}`);

    const chatRooms = await getDoc(
      doc(db, "userChats", metawalletid)
    );

    if (!chatRooms.exists()) {
      setDoc(doc(db, "userChats", metawalletid), {});
    }

    get(child(ref(database), `users/${metawalletid}`)).then(
      (snapshot) => {
        if (snapshot.exists()) {
          const user = snapshot.val()
         if( user.hasOwnProperty('onboarded')){
          if(user.onboarded)
          router.push("/");
          else{
            router.push("/login/intro");
          }
         }
         else{
           router.push("/login/intro");
         }
       
        } else {
          const payload = {
            chainId: data.chainId,
            email: user.email || '',
            metawalletid,
            displayName: user.displayName,
            phoneNumber: user.phoneNumber,
            verified: false,
            brand: false,
            influencer: false,
            purchasesMade: 0,
            onboarded: false,
            rating: null,
            moneySpent: 0,
            photoURL: user.photoURL,
            uid: user.uuid,
          }
         
          set(ref(database, `users/${metawalletid}`), {
          ...payload
          });
          router.push("/login/intro");
        }
      }
    );
  }

  useEffect(() => {
      const handleMetaLogin = async () => {
      
        
          await handleUser({ user:contextObj?.currentUser, metawalletid: acc, data})
       
        }

        const anonSign = async () => {
          const auth = getAuth() 
          await signInAnonymously(auth)
         
        }
      
      if(acc && data && contextObj?.currentUser && Object.keys(contextObj?.currentUser)?.length>0 && contextObj?.currentUser?.isAnonymous){
     
      handleMetaLogin()
      }
      else if (acc && data ){
        anonSign()
      }
  }, [acc, data, contextObj?.currentUser])

  return (
    <div>
      <Meta title="Login " />
      {/* <!-- Login --> */}
      <section className="relative h-screen">
        <div className="lg:flex lg:h-full">
          {/* <!-- Left --> */}
          <div className="relative text-center lg:w-1/2">
            <img
              src="/images/login.jpg"
              alt="login"
              className="absolute h-full w-full object-cover"
            />
            {/* <!-- Logo --> */}
            <Link href="/">
              <a className="relative inline-block py-10">
                <img
                  src="/images/logo_white.png"
                  className="inline-block max-h-7"
                  alt="Xhibiter | NFT Marketplace"
                />
              </a>
            </Link>
          </div>

          {/* <!-- Right --> */}
          <div className="relative flex items-center justify-center p-[10%] lg:w-1/2">
            <picture className="pointer-events-none absolute inset-0 -z-10 dark:hidden">
              <img
                src="/images/gradient_light.jpg"
                alt="gradient"
                className="h-full w-full"
              />
            </picture>

            <div className="w-full max-w-[25.625rem] text-center">
              <h1 className="text-jacarta-700 font-display mb-6 text-4xl dark:text-white">
                {t("loginOrSignup")}
              </h1>
              <p
                className="dark:text-jacarta-300 mb-10 text-lg leading-normal"
                style={{ textTransform: "none" }}
              >
                <Trans
                  i18nKey="loginSubtitle"
                  t={t}
                  values={{
                    target: "marketing",
                  }}
                  components={{
                    spanwrap: (
                      <b className="text-accent font-bold"> placeholder </b>
                    ),
                  }}
                />
              </p>

              <button
                onClick={() => {
                  const provider = new GoogleAuthProvider();
                  const auth = getAuth();
                  signInWithPopup(auth, provider)
                    .then(async (result) => {
                      // This gives you a Google Access Token. You can use it to access the Google API.
                      const credential =
                        GoogleAuthProvider.credentialFromResult(result);
                      const token = credential.accessToken;
                      const user = result.user;

                      const database = getDatabase();
                      const dbRef = ref(database, `users/${user.uid}`);

                      const chatRooms = await getDoc(
                        doc(db, "userChats", user.uid)
                      );

                      if (!chatRooms.exists()) {
                        setDoc(doc(db, "userChats", user.uid), {});
                      }

                      get(child(ref(getDatabase()), `users/${user.uid}`)).then(
                        (snapshot) => {
                          if (snapshot.exists()) {
                            const user = snapshot.val()
                           if( user.hasOwnProperty('onboarded')){
                            if(user.onboarded)
                            router.push("/");
                            else{
                              router.push("/login/intro");
                            }
                           }
                           else{
                             router.push("/login/intro");
                           }
                         
                          } else {
                            set(ref(database, `users/${user.uid}`), {
                              email: user.email,
                              displayName: user.displayName,
                              phoneNumber: user.phoneNumber,
                              verified: false,
                              brand: false,
                              influencer: false,
                              purchasesMade: 0,
                              onboarded: false,
                              rating: null,
                              moneySpent: 0,
                              photoURL: user.photoURL,
                              uid: user.uid,
                            });
                            router.push("/login/intro");
                          }
                        }
                      );
                      // ...
                    })
                    .catch((error) => {
                      // Handle Errors here.
                      const errorCode = error.code;
                      const errorMessage = error.message;
                      // The email of the user's account used.
                      //    const email = error.customData.email;
                      // The AuthCredential type that was used.
                      const credential =
                        GoogleAuthProvider.credentialFromError(error);
                      // ...
                      console.log(error);
                    });
                }}
                className="dark:bg-jacarta-700 dark:border-jacarta-600 border-jacarta-100 dark:hover:bg-accent-dark hover:bg-accent text-jacarta-700 mb-4 flex w-full items-center justify-center rounded-full border-2 bg-white py-4 px-8 text-center font-semibold transition-all hover:border-transparent hover:text-white dark:text-white dark:hover:border-transparent"
              >
                <GoogleIcon color="primary" />
                <span className="ml-2">{t("googleLogin")}</span>
              </button>

              <Metamask_comp_login setAccount={setAcc} setData={setData}/>

              {/* <FacebookLogin
  appId="654129256407391"
  autoLoad
  callback={responseFacebook}
  render={renderProps => (
    <button onClick={renderProps.onClick}  
     className="dark:bg-jacarta-700 dark:border-jacarta-600 border-jacarta-100 dark:hover:bg-accent hover:bg-accent text-jacarta-700 mb-4 flex w-full items-center justify-center rounded-full border-2 bg-white py-4 px-8 text-center font-semibold transition-all hover:border-transparent hover:text-white dark:text-white dark:hover:border-transparent">
    <FacebookIcon color="primary"/>
       <span className="ml-2">Facebook login</span></button>
  )}
/> */}
            </div>
          </div>
        </div>
      </section>
      {/* <!-- end login --> */}
    </div>
  );
};

export default Login;
